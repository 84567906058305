// Import necessary React modules
import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import axios from 'axios';
import { updateProfile } from "firebase/auth";

import IconButton from '@mui/joy/IconButton';
import Snackbar from '@mui/joy/Snackbar'
import Chip from '@mui/joy/Chip';
import Modal from '@mui/joy/Modal';
import Sheet from '@mui/joy/Sheet';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import Input from '@mui/joy/Input';

import Icon from '@mdi/react';
import { mdiAccountCircle } from '@mdi/js';
import { mdiDelete } from '@mdi/js';
import { mdiCheck } from '@mdi/js';
import { mdiPencilOutline } from '@mdi/js';


// Allow users to customize their profiles with additional details such as graduation year, major, etc.
export const ProfilePage = () => {
  const api = axios.create({
    baseURL: 'https://illini.onrender.com/',
  });
  // State for user and contributions
  const [user, setUser] = useState(null);
  const [contributions, setContributions] = useState([]);
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const [isUsernameSnackbarOpen, setUsernameSnackbarOpen] = useState(false);
  const [usernameModalOpen, setUsernameModalOpen] = useState(false);
  const [newUsername, setNewUsername] = useState('');
  const [isUsernameChanged, setIsUsernameChanged] = useState(false);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleUsernameSnackbarClose = () => {
    setUsernameSnackbarOpen(false);
  };

  // Effect for fetching user data and contributions
  useEffect(() => {
    const auth = getAuth();

    // Add an authentication state listener
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        setUser(user);
        setNewUsername(user.displayName)
      } else {
        // User is signed out
        setUser(null);
      }
    });

    // Define the server endpoint
    const serverEndpoint = user
      ? 'api/blog?where={"UID":"' + user.uid + '"}'
      : '';

    // Fetch contributions from the server
    api.get(serverEndpoint)
      .then(response => {
        setContributions(response.data.data.reverse());
      })
      .catch(error => {
        console.error('Error fetching contributions:', error.message);
      });

    // Clean up the listener when the component is unmounted
    return () => unsubscribe();
  }, [user]);

  useEffect(() => {
    if (isUsernameChanged && user) {
      updateProfile(user, { displayName: newUsername })
        .then(() => {
          setIsUsernameChanged(false); // Reset the flag after username update
        })
        .catch((error) => {
          console.error('Error updating profile:', error.message);
        });
    }
  }, [isUsernameChanged]);

  const handleDelete = (index) => {
    if (window.confirm('Are you sure you want to delete this memory?')) {
      const serverEndpoint = 'api/blog/' + contributions[index]._id;
      api.delete(serverEndpoint).then((response) => {
          console.log("Deleted memory ", contributions[index]._id)
      }).catch(error => {
          console.error('Error deleting:', error.message);
      });
      const updatedContributions = contributions.filter((_, i) => i !== index);
      setContributions(updatedContributions);
      setSnackbarOpen(true)
    }
  };

  const handleOpenUsernameModal = () => {
    console.log("hi")
    setUsernameModalOpen(true);
  }

  const handleChangeUsername = () => {
    console.log('change username')
    console.log(user.displayName)
    setIsUsernameChanged(true);
    setUsernameModalOpen(false);
    setUsernameSnackbarOpen(true)
  }

  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);
    
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    
    const formattedDate = `${month}/${day}/${year}`;
    
    return formattedDate;
  }

  return (
    <>
      <Modal
        open={usernameModalOpen}
        onClose={() => setUsernameModalOpen(false)}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Sheet
          variant="outlined"
          sx={{
            maxWidth: 340,
            borderRadius: "md",
            p: 3,
            boxShadow: "lg",
          }}
        >
          <ModalClose variant="plain" size="sm" sx={{ m: 1 }} />
          <Typography
            id="modal-title"
            level="h6"
            textColor="inherit"
            fontWeight="lg"
            mb={1}
          >
            Edit username
          </Typography>
          <Input
            onChange={(e) => setNewUsername(e.target.value)}
            endDecorator={<Button onClick={handleChangeUsername}>OK</Button>} >
          </Input>
        </Sheet>
      </Modal>

      <div className='flex flex-col items-center mt-4'>
        <Icon color="#1F2B53" path={mdiAccountCircle} size={9}/>
        <div className='flex items-start justify-center mt-6 gap-x-2'>
          <IconButton>
            <Icon color="white" disabled={true} /> 
          </IconButton>
          <p className={`text-4xl font-bold ${!user?.displayName && 'text-slate-500'}`}>
            {user?.displayName ? user.displayName : 'Add a username'}
          </p>
          <IconButton onClick={handleOpenUsernameModal} sx = {{ mt: .2}}>
            <Icon color="#94a3b8" path={mdiPencilOutline} size={1}/> 
          </IconButton>
        </div>
        <p className='text-xl mt-1'>{user?.email}</p>
      </div>
      <div className='relative mx-8 md:mx-24 mt-12 mb-4 flex flex-row justify-between text-3xl'>
        <p className="font-bold">Your Memories</p>
        <p className="font-bold text-slate-400">{contributions.length}</p>
      </div>
      <ul className='relative list-none flex flex-wrap justify-evenly items-start mb-8'>
        {contributions.map((contribution, index) => (
          <li key={index} className={`w-[360px] flex justify-between items-center p-5 rounded-2xl m-4 ${index % 2 === 0 ? 'bg-[#E97630]' : 'bg-[#1F2B53]'}`}>
            <div>
              <p className="font-mono text-white text-sm mb-0 text-left">{formatTimestamp(contribution.createdAt)}</p>
              <p className="text-white text-lg text-left pr-2">{contribution.content}</p>
              <div className="mt-1">
                {contribution.tags.map((name) => (
                    <Chip
                    key={name}
                    variant="outlined"
                    color={name === 'Unofficial' ? 'success' : 'primary'}
                    sx={{
                        fontSize: '12px',
                        m: '2px',
                    }}
                    >
                    {name}
                    </Chip>
                ))}
              </div>
            </div>
            <IconButton color="danger" variant="plain" onClick={() => handleDelete(index)}>
              <Icon path={mdiDelete} size={1} />
            </IconButton>
          </li>
        ))}
      </ul>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={4000}
        color='success'
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handleSnackbarClose}
        endDecorator={<Icon path={mdiCheck} size={1} />}
      >Memory deleted</Snackbar>

      <Snackbar
        open={isUsernameSnackbarOpen}
        autoHideDuration={4000}
        color='success'
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handleUsernameSnackbarClose}
        endDecorator={<Icon path={mdiCheck} size={1} />}
      >Updated username</Snackbar>
    </>
  );
};

// Export the ProfilePage component
export default ProfilePage;
