import React, { useState, useEffect } from "react";

import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Sheet from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button"; // Ensure Button is imported if not already

// TODO: add a button to say begin, revamp in general
  // I did 80% of this. Read README.md for more details
// TODO: walkthrough of all the functionality, how to see and create memories

export const Welcome = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("hasVisitedBefore")) {
      setOpen(true);
      console.log(localStorage);
      localStorage.setItem("hasVisitedBefore", true);
    }
  }, []);

  return (
    <Modal
      disableAutoFocus={true}
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={open}
      onClose={() => setOpen(false)}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Sheet
        variant="soft"
        sx={{
          textAlign: "center",
          maxWidth: 500,
          borderRadius: "md",
          p: 3,
          boxShadow: "lg",
          transitionDuration: "0.2s",
        }}
      >
        <ModalClose variant="plain" sx={{ m: 1 }} />
        <Typography
          component="h2"
          id="modal-title"
          level="h3"
          textColor="inherit"
          fontWeight="lg"
          mb={1}
        >
          Welcome to Illini Memories!
        </Typography>
        <Typography mb={1} mt={2} textAlign="left">
          Every student, past, present, and future, has unique memories and experiences on campus. Don't let your memories get lost for eternity.
        </Typography>
        <Typography mb={3} /> {/* Add more space before the bullet points */}
        <Typography component="ul" sx={{ textAlign: "left", pl: 3, '& li': { mb: 2 } }}> {/* Increase spacing between bullet points */}
          <li>🔍 Browse memories created by other students.</li>
          <li>💬 Share your memories with the world publicly or anonymously.</li>
          <li>🤫 Discover secrets about other students, UIUC, and unknown places.</li>
        </Typography>

        <Button 
          variant="contained" 
          onClick={() => setOpen(false)} 
          sx={{ 
            mt: 2, 
            borderRadius: "20px", 
            bgcolor: "#0b6bcb", 
            border: "2px", 
            fontSize: "large", // Increase font size
            padding: "10px 20px", // Make button bigger
            color: "white", // Change letter color to white
            '&:hover': { 
              bgcolor: '#FF5F0F'
              // border: "2px solid orange" 
            }
          }}>Begin</Button>

      </Sheet>
    </Modal>
  );
};

export default Welcome;
