import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import Chip from "@mui/joy/Chip";
import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";

import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import CreateMemory from "../CreateMemory/CreateMemory";
import Menu from "../Menu/Menu";
import Welcome from "../Welcome/Welcome";
import LoadingMemories from "../LoadingMemories/LoadingMemories";

import "./MapComponent.scss";

import axios from "axios";

mapboxgl.accessToken =
  "pk.eyJ1Ijoic2FoYXNyYW1lc2giLCJhIjoiY2xveXlkaGl2MDd5cjJrbjFrdjczYmtucCJ9.dKaRhEFs4Fw4YcRVzp7zZg";

// Map Component
// This component is responsible for rendering the map
// Create the popup
// Make user login/signup, then allow them to create a new memory and store it in the MONGODB database
// Inside the popup, the users will create a new memory (title, description)
// these memories can be thought of as blog posts
// this memory will be stored in the database
// The popup will be associated with a marker
// The marker will be associated with a location (lat, lng)
// The location will be associated with a click event

export const MapComponent = () => {
  const api = axios.create({
    baseURL: "https://illini.onrender.com/",
  });
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(-88.227211);
  const [lat, setLat] = useState(40.107211);
  const [zoom, setZoom] = useState(16.46);
  const [user, setUser] = useState(null);
  const [username, setUsername] = useState("Anonymous");
  const [uid, setUid] = useState(null);
  const [memoriesLoaded, setMemoriesLoaded] = useState(false);

  const markerClasses = ["jones-m", "kingfisher-m", "logo-m", "pintobean-m", "alma-m"];
  const seenMarker = {};

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        setUid(auth.currentUser.uid);
        setUsername(
          auth.currentUser.displayName || auth.currentUser.email.split("@")[0],
        );
      }
    });

    const serverEndpoint = "api/blog";
    api
      .get(serverEndpoint)
      .then((response) => {
        // console.log(response.data.data);
        response.data.data.forEach((post) => {
          const { latitude, longitude } = post
          const coords = `${latitude},${longitude}`;
          if (seenMarker[coords]) { return; }

          // console.log("LAT: ", post.latitude, "LNG: ", post.longitude);
          createMemory(
            post.longitude,
            post.latitude,
            post.content,
            post.isPublic ? post.author : "Anonymous",
            post.UID,
            uid,
            post._id,
            post.tags,
            post.markerImage || "random"
          );
          seenMarker[coords] = true;
        });
        setMemoriesLoaded(true);
      })
      .catch((error) => {
        // console.error("Error getting:", error.message);
      });

    // Cleanup the observer when the component unmounts
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        setUid(auth.currentUser.uid);
        setUsername(
          auth.currentUser.displayName || auth.currentUser.email.split("@")[0],
        );
      } else {
        setUser("Anonymous");
      }
    });
  });

  const createMemory = (
    lng,
    lat,
    description,
    username,
    postUid,
    currUid,
    memoryId,
    tags,
    markerImage,
  ) => {
    const isLongDescription = description.length > 200;
    const shortDescription = isLongDescription ? description.substring(0, 200) + '...' : description;

    const handleReadMoreClick = (popup, fullDescription) => {
      const newContent = (
         <div className={isLongDescription ? 'popup-content' : 'popup-content-short'}>
           <div className='font-bold'>{username}</div>
           <div className='text-left'>{fullDescription}</div>
           <div>
             {tags.map((name) => (
               <Chip
                 key={name}
                 variant="outlined"
                 color={name === "Unofficial" ? "success" : "primary"}
                 sx={{
                   fontSize: "12px",
                   m: "2px",
                 }}
               >
                 {name}
               </Chip>
             ))}
           </div>
         </div>
      );
     
      const container = document.createElement('div');
      ReactDOM.render(newContent, container);
      popup.setDOMContent(container);
     };
     
 
  
    const popupContent = (
      <div>
        <div className='font-bold'>{username}</div>
        <div className='text-left'>
          {isLongDescription ? (
            <>
              {shortDescription}
              <button onClick={(e) => {
                e.preventDefault();
                // Update the popup content with the full description
                handleReadMoreClick(popup, description);
            }}>Continue reading</button>
            </>
          ) : (
            <>{description}</>
          )}
        </div>
        <div>
          {tags.map((name) => (
            <Chip
              key={name}
              variant="outlined"
              color={name === "Unofficial" ? "success" : "primary"}
              sx={{
                fontSize: "12px",
                m: "2px",
              }}
            >
              {name}
            </Chip>
          ))}
        </div>
      </div>
    );
    
    const el = document.createElement('div');
    if (markerImage === 'random') {
      el.className = markerClasses[Math.floor(Math.random() * markerClasses.length)];
    } else {
      el.className = markerImage;
    }
    const marker = new mapboxgl.Marker(el)
      .setLngLat([lng, lat])
      .addTo(map.current);
  
    const popupContainer = document.createElement("div");
    ReactDOM.render(popupContent, popupContainer);
  
    const popup = new mapboxgl.Popup({ offset: 25 }).setDOMContent(
      popupContainer,
    );
  
    marker.setPopup(popup);
  };
  
  useEffect(() => {
    if (map.current) return;
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center: [lng, lat],
      zoom: zoom,
    });


    map.current.on("move", () => {
      setLng(map.current.getCenter().lng.toFixed(6));
      setLat(map.current.getCenter().lat.toFixed(6));
      setZoom(map.current.getZoom().toFixed(2));
    });
  }, [lat, lng, zoom]);

  return (
    <>
      <Welcome />
      {!memoriesLoaded && <LoadingMemories />}
      {/* <div className="sidebar">
        Latitude: {lat} | Longitude: {lng}
      </div> */}
      <div ref={mapContainer} className="map-container"></div>
      <div className="crosshair"></div>
      <Menu />
      <CreateMemory
        createMemory={createMemory}
        lng={lng}
        lat={lat}
        username={username}
        uid={uid}
      />
      {/* <Search /> */}
    </>
  );
};

export default MapComponent;
