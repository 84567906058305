import React, { useEffect, useState } from "react";
import Dropdown from "@mui/joy/Dropdown";
import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import Button from "@mui/joy/Button";

import Icon from "@mdi/react";
import { mdiMenu } from "@mdi/js";

import { signOut } from "firebase/auth";
import { auth } from "../../firebase";

import { useNavigate } from "react-router-dom";
import "./Menu.scss";

export const MenuComponent = () => {
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setShowMenu(true);
      } else {
        setShowMenu(false);
      }
    });
  }, []);

  const handleProfileClick = () => {
    navigate("/profile");
  };

  const handleLogoutClick = () => {
    if (window.confirm("Sign out of Illini Memories?")) {
      signOut(auth);
      navigate("/signin");
    }
  };

  const handleSignIn = () => {
    navigate("/signin");
  };

  const aboutClick = () => {
    navigate("/about");
  }

  return (
    <>
      {showMenu && (
        <Dropdown>
          <MenuButton
            variant="soft"
            sx={{
              zIndex: 1,
              position: "absolute",
              top: 0,
              right: 0,
              m: 4,
              p: 1,
            }}
          >
            <Icon path={mdiMenu} size={1} />
          </MenuButton>
          <Menu>
            <MenuItem onClick={handleProfileClick}>Profile</MenuItem>
            <MenuItem onClick={aboutClick}>About</MenuItem>
            <MenuItem onClick={handleLogoutClick}>Sign out</MenuItem>
          </Menu>
        </Dropdown>
      )}
      {!showMenu && (
        <Button
        onClick={handleSignIn}
        variant="solid"
        color="neutral"
        sx={{
          zIndex: 1,
          position: "absolute",
          top: 0,
          right: 0,
          m: 4,
          px: 3,
        }}>Sign in</Button>
      )}
    </>
  );
};

export default MenuComponent;
