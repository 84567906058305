import React, { useState, useEffect } from 'react';
import { TypeAnimation } from 'react-type-animation';

import Modal from "@mui/joy/Modal";
import Sheet from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";

export const LoadingMemories = () => {
  const [showAnimation, setShowAnimation] = useState(false);
  const [tips, setTips] = useState(
    [
      'Add Illini Memories to your home screen! On your phone, click the share icon, scroll down and click Add to Home Screen',
      'You can view and delete your memories on the Profile page',
      'Stay on the lookout for fun new seasonal tags every month',
      'Illini make memories around the world, zoom out to find out what your classmates get up to off campus...',
    ]
  );
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAnimation(true);
    }, 2000);

    for (let i = tips.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [tips[i], tips[j]] = [tips[j], tips[i]];
    }
    setTips(tips);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Modal
        disableAutoFocus={true}
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={true}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Sheet
          variant="outlined"
          sx={{
            minWidth: 300,
            maxWidth: 300,
            borderRadius: 'md',
            p: 3,
            boxShadow: 'lg',
          }}
        >
          <Typography id="modal-desc" textColor="text.tertiary">
            <div className='text-center font-bold'>Loading memories...</div>
          </Typography>
          {showAnimation && (
            <TypeAnimation
              sequence={[
                tips[0],
                5000,
                tips[1],
                5000,
                tips[2],
                5000,
                tips[3],
                5000
              ]}
              wrapper="span"
              speed={70}
              deletionSpeed={100}
              style={{ fontSize: '1em', display: 'inline-block', marginTop: '1.5rem'}}
              repeat={Infinity}
              cursor={false}
            />
          )}
        </Sheet>
      </Modal>
  );
};

export default LoadingMemories;
