import { React, useState, useEffect } from "react";
import { auth } from "../../firebase.js";
import { getAuth, onAuthStateChanged, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from 'react-router-dom';

import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Link from '@mui/joy/Link';

import GoogleOriginal from 'devicons-react/lib/icons/GoogleOriginal';


export const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [problem, setProblem] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();

    // Add an authentication state listener
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate('/');
      }
    });

    // Clean up the listener when the component is unmounted
    return () => unsubscribe();
  }, [navigate]);
  
  const handleLogin = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredentials) => {
        // if (userVerified()) {
          // const user = userCredentials.user;
          // console.log("Logged in with:", user.email);
          navigate('/');
          //navigation.replace("Tabs");
          // AsyncStorage.setItem("LoggedIn", JSON.stringify(true));
        // } else {
        //   alert("Please verify your email.");
        // }
      })
      .catch((error) => {
        console.log(error.message)
        setProblem(true);
        }
      );
  };

  const handleGoogleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        navigate('/');
      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
        console.error( );
      });
  };

  return (
    <div className='flex flex-col justify-center items-center'>
      <div className='mt-4'>
        <img src={process.env.PUBLIC_URL + '/logo-transparent.png'} width='80rem' alt='Illini Memories'/>
      </div>
      <div className="text-xl mt-4 mb-2">Sign in to Illini Memories</div>
      {problem && <div className='bg-red-200 m-4 p-4 rounded-lg w-[320px] text-sm'>
        Incorrect email or password
      </div>}
      <div className='bg-slate-200 m-2 p-4 rounded-lg w-[320px]'>
        <FormControl>
          <FormLabel
          size="lg" >
            Email address
          </FormLabel>
          <Input
          size="md" 
          onChange={(text) => setEmail(text.target.value)} 
          type="text" 
          id="username" 
          required
          sx={{
            maxWidth: 320,
          }} />
        </FormControl>
        
        <div className='mt-3'>
          <FormControl>
            <FormLabel
            size="lg" >
              Password
            </FormLabel>
            <Input
            size="md" 
            onChange={(text) => {setPassword(text.target.value)}}
            type="password" 
            id="password" 
            required
            sx={{
              maxWidth: 320,
            }} />
          </FormControl>
        </div>

        <Button size="md" type="submit" onClick={() => handleLogin()} sx={{
              minWidth: '100%',
              mt: 2,
            }}>Sign in</Button>

        <Button color="neutral" variant="outlined" onClick={handleGoogleSignIn} sx={{ minWidth: '100%', mt: 2 }}>
          <div className='flex gap-x-2'>
            <div><GoogleOriginal size={20}/></div>
            
            Continue with Google
          </div>
        </Button>
      </div>

      <div className='text-sm mt-2'>
        Don't have an account? &nbsp;
        <Link level="body-sm" onClick={() => {navigate('/signup')}}>Sign up</Link>
      </div>

    </div>
  );
};



export default SignIn;
