import React from 'react';
import './AboutPage.scss';


const AboutPage = () => {
  return (
    <div className="about-container">
      <h1>About Illini Memories</h1>
      
      <section>
        <h2>What It Is</h2>
        <p>Illini Memories is a platform for you to share cool stories and adventures from your time at UIUC. It's a way to connect with other Illini and relive the good times. You can share your memories and see what others have shared.</p>
            
      </section>
      
      {/* <section>
        <h2>Vision/Why</h2>
        <p>The vision behind Illini Memories is to preserve the rich tapestry of life at UIUC. Every person connected to the university has unique stories that contribute to the campus's identity, yet these stories often fade over time or remain unheard. Illini Memories aims to bridge the gap between past and present Illini, fostering a sense of community and belonging. It serves as a digital time capsule, ensuring that the spirit, challenges, and triumphs of UIUC's community are remembered and celebrated.</p>
      </section> */}
      
      <section>
        <h2>Contact</h2>
        <p>For inquiries, support, or suggestions, please reach out to us <a href="mailto:jcampo37@illinois.com">here</a>. We're always looking to improve and expand the Illini Memories experience and welcome feedback from our community.</p>
      </section>
    </div>
  );
};

export default AboutPage;