import './App.scss';
import { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom';

import SignIn from './components/SignIn/SignIn'
import SignUp from './components/SignUp/SignUp'
import MapComponent from './components/MapComponent/MapComponent';
import ProfilePage from './components/ProfilePage/ProfilePage';
import AboutPage from './components/About/AboutPage';


function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<MapComponent/>}/>
        <Route path='/signin' element={<SignIn/>}/>
        <Route path='/signup' element={<SignUp/>}/>
        <Route path='/profile' element={<ProfilePage/>}/>
        <Route path='/about' element={<AboutPage/>}/>
      </Routes>
    </Router>
  );
}

export default App;

// App.js


