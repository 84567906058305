import React, { useState } from "react";

import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Checkbox from "@mui/joy/Checkbox";
import Chip from "@mui/joy/Chip";
import IconButton from "@mui/joy/IconButton";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import ModalDialog from '@mui/joy/ModalDialog';
import Option from "@mui/joy/Option";
import Select, { selectClasses } from "@mui/joy/Select";
import Sheet from "@mui/joy/Sheet";
import Snackbar from "@mui/joy/Snackbar";
import Textarea from "@mui/joy/Textarea";
import Tooltip from "@mui/joy/Tooltip";
import Typography from "@mui/joy/Typography";
import Radio from '@mui/joy/Radio';
import RadioGroup from '@mui/joy/RadioGroup';
import Avatar from '@mui/joy/Avatar';

import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import axios from "axios";

import SignedInModal from '../SignedInModal/SignedInModal';

import { mdiCheck, mdiChevronDown, mdiMapMarkerPlus } from "@mdi/js";
import Icon from "@mdi/react";

import "./CreateMemory.scss";

export const CreateMemory = ({ createMemory, lng, lat, username, uid }) => {
  const api = axios.create({
    baseURL: "https://illini.onrender.com/",
  });
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [signedInModal, setSignedInModal] = useState(false);
  const [selected, setSelected] = useState([]);
  const [description, setDescription] = useState("");
  const [markerImage, setMarkerImage] = useState("logo-m");
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const [visibility, setVisibility] = useState(true);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleVisibility = (event, newValue) => {
    setVisibility(newValue === "Public");
  };

  const handlePinClick = () => {
    auth.onAuthStateChanged((user) => {      
      if (!user) {
        setSignedInModal(true);
      } else {
        setOpen(true);
      }
    });
  };

  const postMemory = () => {
    const postData = {
      content: description,
      author: username,
      UID: uid,
      latitude: lat,
      longitude: lng,
      isPublic: visibility,
      tags: selected,
      markerImage: markerImage,
    };
    console.log(postData);

    const serverEndpoint = "api/blog";

    api
      .post(serverEndpoint, postData)
      .then((response) => {
        createMemory(
          lng,
          lat,
          description,
          visibility === false ? "Anonymous" : username,
          uid,
          uid,
          response.data._id,
          selected,
          markerImage
        );
        console.log("Posted successfully:", response.data);
      })
      .catch((error) => {
        console.error("Error posting:", error.message);
      });

    setOpen(false);
    setSnackbarOpen(true);
    setDescription("");
    setSelected([]);
  };

  return (
    <>
      <Tooltip title="Create a memory at this location" placement="left">
        <IconButton
          color="primary"
          variant="solid"
          onClick={handlePinClick}
          sx={{
            zIndex: 1,
            position: "absolute",
            bottom: 0,
            right: 0,
            m: 4,
            p: 2,
            borderRadius: "50%",
          }}
        >
          <Icon path={mdiMapMarkerPlus} size={2} />
        </IconButton>
      </Tooltip>
      
      <Modal open={signedInModal} onClose={() => setSignedInModal(false)}>
        <ModalDialog variant="outlined">
          <DialogContent>
            Ready to make some memories? Sign up to get started!
          </DialogContent>
          <DialogActions>
            <Button variant="solid" color="primary" onClick={() => navigate('/signup')}>
              Sign up
            </Button>
            <Button variant="soft" color="neutral" onClick={() => setSignedInModal(false)}>
              Go back
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>

      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Sheet
          variant="outlined"
          sx={{
            textAlign: "center",
            maxWidth: 500,
            borderRadius: "md",
            p: 3,
            boxShadow: "lg",
            transitionDuration: "0.2s",
          }}
        >
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <Typography
            component="h2"
            id="modal-title"
            level="h3"
            textColor="inherit"
            fontWeight="lg"
            mb={1}
          >
            Create Memory
          </Typography>
          <Textarea
            sx = {{ mt: 2, p: 1 }}
            placeholder='Write something...'
            minRows={3}
            maxRows={3}
            size="md"
            onChange={(text) => setDescription(text.target.value)}
            type="text"
            required
          />
          <Box>
            <div>
              <Typography mb={1} mt={2} textAlign="left">
                Add tags
              </Typography>
              <Box
                role="group"
                sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}
              >
                {[
                  "Campus Artistry",
                  "Chill Zones",
                  "Clubbing Circuit",
                  "Failed Exam Blues",
                  "Fitness Freak",
                  "Foodie Finds",
                  "Hidden Gems",
                  "Late Night Adventures",
                  "Study Haven",
                  "Unofficial",
                ].map((name) => {
                  const checked = selected.includes(name);
                  return (
                    <Chip
                      key={name}
                      variant="plain"
                      color={checked ? "primary" : "neutral"}
                      startDecorator={
                        checked && <Icon path={mdiCheck} size={1} />
                      }
                    >
                      <Checkbox
                        variant="outlined"
                        color={
                          checked
                            ? name === "Unofficial"
                              ? "success"
                              : "primary"
                            : name === "Unofficial"
                              ? "success"
                              : "neutral"
                        }
                        disableIcon
                        overlay
                        label={name}
                        checked={checked}
                        onChange={(event) => {
                          setSelected((names) =>
                            !event.target.checked
                              ? names.filter((n) => n !== name)
                              : [...names, name],
                          );
                        }}
                      />
                    </Chip>
                  );
                })}
              </Box>
            </div>
          </Box>

          <Typography mb={1} mt={2} textAlign="left">
            Visibility
          </Typography>

          <Select
            placeholder="Select visibility..."
            indicator={<Icon path={mdiChevronDown} size={1} />}
            onChange={handleVisibility}
            sx={{
              width: 240,
              [`& .${selectClasses.indicator}`]: {
                transition: "0.2s",
                [`&.${selectClasses.expanded}`]: {
                  transform: "rotate(-180deg)",
                },
              },
            }}
          >
            <Option value="Public">Public</Option>
            <Option value="Anonymous">Anonymous</Option>
          </Select>

          <div className='flex items-center gap-2 mt-6'>
            <Typography id="segmented-controls-example" fontSize="md">
              Marker:
            </Typography>
            <RadioGroup
              orientation="horizontal"
              aria-labelledby="segmented-controls-example"
              name="justify"
              value={markerImage}
              onChange={(event) => setMarkerImage(event.target.value)}
              sx={{
                minHeight: 50,
                padding: '4px',
                borderRadius: '12px',
                bgcolor: 'neutral.softBg',
                '--RadioGroup-gap': '4px',
                '--Radio-actionRadius': '8px',
              }}
            >
              {['logo-m', 'pintobean-m', 'alma-m', 'kingfisher-m', 'jones-m'].map((item) => (
                <Radio
                  key={item}
                  color="neutral"
                  value={item}
                  disableIcon
                  label={<Avatar variant="plain" src={`./markers/${item}.png`} alt={item} />}
                  variant="plain"
                  sx={{
                    px: 0.2,
                    alignItems: 'center',
                  }}
                  slotProps={{
                    action: ({ checked }) => ({
                      sx: {
                        ...(checked && {
                          bgcolor: 'background.surface',
                          boxShadow: 'sm',
                          '&:hover': {
                            bgcolor: 'background.surface',
                          },
                        }),
                      },
                    }),
                  }}
                />
              ))}
            </RadioGroup>
          </div>
          <Button
            size="md"
            type="submit"
            onClick={postMemory}
            // startDecorator={<Icon path={mdiMapMarkerPlus} size={1} />}
            sx={{ mt: 4, minWidth: 100 }}
          >
            Post
          </Button>
        </Sheet>
      </Modal>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={4000}
        color="success"
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handleSnackbarClose}
        endDecorator={<Icon path={mdiCheck} size={1} />}
      >
        Memory created!
      </Snackbar>
    </>
  );
};

export default CreateMemory;
