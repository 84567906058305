import { React, useState } from "react";
import { auth } from "../../firebase.js";
import { createUserWithEmailAndPassword, updateProfile, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
// import { sendEmailVerification } from "firebase/auth";
import { useNavigate } from 'react-router-dom';

import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Link from '@mui/joy/Link';

import GoogleOriginal from 'devicons-react/lib/icons/GoogleOriginal';

export const SignUp = () => {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [problem, setProblem] = useState(false);
  const navigate = useNavigate();

  const handleSignUp = async () => {
    if (confirmPassword()) {
      setProblem(false);
    } else {
      setProblem(true);
      return;
    }
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      await updateProfile(user, { displayName: username });
      
      console.log('User created successfully!');
      navigateSignIn(); // Redirect to your dashboard or wherever after successful sign-up
    } catch (error) {
      console.error('Error creating user:', error.message);
    }
  };


  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      const user = result.user;
      navigateSignIn();
    } catch (error) {
      console.error('Error signing in with Google:', error.message);
    }
  };

  const confirmPassword = () => {
    return password === confirmPass;
  };

  const navigateSignIn = () => {
    navigate('/signin');
  }

  return (
    <div className='flex flex-col justify-center items-center'>
      <div className='mt-4'>
        <img src={process.env.PUBLIC_URL + '/logo-transparent.png'} width='80rem' alt='Illini Memories'/>
      </div>
      <div className="text-xl mt-4 mb-2">Create your account</div>
      {problem && <div className='bg-red-200 m-4 p-4 rounded-lg w-[320px] text-sm'>
        Passwords don't match
      </div>}
      <div className='bg-slate-200 m-2 p-4 rounded-lg w-[320px]'>
        <FormControl>
          <FormLabel
          size="lg" >
            Email address
          </FormLabel>
          <Input
          size="md" 
          onChange={(text) => setEmail(text.target.value)} 
          type="text" 
          id="username" 
          required
          sx={{
            maxWidth: 320,
          }} />
        </FormControl>
        
        <div className='mt-3'>
          <FormControl>
            <FormLabel
            size="lg" >
              Username
            </FormLabel>
            <Input
            size="md" 
            onChange={(text) => setUsername(text.target.value)} 
            type="text" 
            id="username" 
            required
            sx={{
              maxWidth: 320,
            }} />
          </FormControl>
        </div>
        
        <div className='mt-3'>
          <FormControl>
            <FormLabel
            size="lg" >
              Password
            </FormLabel>
            <Input
            size="md" 
            onChange={(text) => {setPassword(text.target.value)}}
            type="password" 
            id="password" 
            required
            sx={{
              maxWidth: 320,
            }} />
          </FormControl>
        </div>

        <div className='mt-3'>
          <FormControl>
            <FormLabel
            size="lg" >
              Confirm password
            </FormLabel>
            <Input
            size="md" 
            onChange={(text) => {setConfirmPass(text.target.value)}}
            type="password" 
            id="password" 
            required
            sx={{
              maxWidth: 320,
            }} />
          </FormControl>
        </div>

        <Button size="md" type="submit" onClick={() => handleSignUp()} sx={{
              minWidth: '100%',
              mt: 2,
            }}>Sign up</Button>

        <Button color="neutral" variant="outlined" onClick={signInWithGoogle} sx={{ minWidth: '100%', mt: 2 }}>
          <div className='flex gap-x-2'>
            <div><GoogleOriginal size={20}/></div>
            
            Continue with Google
          </div>
        </Button>
      </div>

      <div className='text-sm mt-2'>
        Have an account? &nbsp;
        <Link level="body-sm" onClick={() => {navigate('/signin')}}>Sign in</Link>
      </div>

    </div>
  );
};

export default SignUp;
